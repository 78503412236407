import { Text } from "components/core";
import { CardWrapper } from "./HelpOptions.styles";
import { useBoolean } from "hooks";
import { Player } from "components/common";
import { Modal } from "semantic-ui-react";

type TProps = {
  description: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  src: string;
};

const OptionCard: React.FC<TProps> = ({ icon: Icon, src, description }) => {
  const [open, onOpen, onClose] = useBoolean();

  const isYoutubeLink = (str: string) => {
    return str.includes("https://youtu.be/");
  };

  const handleSrc = () => {
    if (isYoutubeLink(src)) {
      onOpen();
    }
  };

  return (
    <CardWrapper onClick={handleSrc}>
      <Icon />
      <Text content={description} variant="alpha" />
      <Modal
        open={open}
        onClose={onClose}
        style={{
          padding: "unset",
          margin: "unset",
        }}
      >
        <Player url={src} />
      </Modal>
    </CardWrapper>
  );
};

export default OptionCard;

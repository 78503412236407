import styled from "styled-components";
import { Button as SemanticButton, ButtonProps } from "semantic-ui-react";
import ThemeHelper from "theme/themeHelper";
import { css } from "styled-components";
import { lighten } from "polished";

const helper = new ThemeHelper();

const HelpButton = styled(SemanticButton)<ButtonProps>`
  &&& {
    font-family: ${({ theme }) => theme.fonts.poppins};
    margin: unset;
    ${helper.getFontWeight("medium")}
    border-radius: 10px;
    ${({ theme }) => {
      const { colors } = theme;
      return css`
        background-color: ${colors.beta};
        color: ${colors.alpha};
        &:hover {
          background-color: ${colors.beta};
          color: ${colors.alpha};
        }
        &:disabled {
          background-color: ${lighten(0.4, colors.beta)};
          color: ${colors.alpha};
        }
        &:active {
          background-color: ${colors.beta};
          color: ${colors.alpha};
        }
        &:focus {
          background-color: ${colors.beta};
          color: ${colors.alpha};
        }
      `;
    }}
    ${({ mb, mt, ms, me }) =>
      helper.getMargins({ top: mt, bottom: mb, left: ms, right: me })}
  }
`;

export { HelpButton };

import { ReactComponent as VideoIcon } from "assets/icons/ContentCreation/video.svg";
import { ReactComponent as DocsIcon } from "assets/icons/ContentCreation/docs.svg";

const data = [
  {
    id: 1,
    description: "AI Content Generation Walkthrough",
    src: "https://youtu.be/KoYrvOmBxsw",
    icon: VideoIcon,
  },
  {
    id: 2,
    description: "How to schedule entire month's posts in a single click",
    icon: DocsIcon,
    src: "",
  },
  {
    id: 3,
    description: "How to decide the best prompt for generating 30 Tweets",
    icon: DocsIcon,
    src: "",
  },
];

export default data;

import styled from "styled-components";

const OptionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.extraLightGrey};
  width: 348px;
  padding: 0.75rem;
  position: absolute;
  z-index: 3;
  top: 20%;
  right: 5%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  padding: 0.75rem;
  cursor: pointer;

  svg {
    width: 48px;
    height: 48px;
  }
`;

export { OptionWrapper, CardWrapper };

import { Flex, Form, Text } from "components/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { useState } from "react";
import { Button } from "semantic-ui-react";
import {
  FormContainer,
  FormWrapper,
  InitialFormContainer,
  InitialFormWrapper,
} from "./ContentGenerationForm.styles";
import { useContentGeneration } from "hooks/Twitter";
import { PulseLoader } from "components/common";
import { useAppSelector } from "redux/store/hooks";
import { CSSProperties } from "styled-components";
import { DolphyHelp } from "../common";

const keywordsValidationSchema = Yup.object({
  keywords: Yup.array(),
});

const urlValidationSchema = Yup.object({
  url: Yup.string(),
});

const urlFormValues = {
  url: "",
};

const keywordsFormValues = {
  keywords: [""],
};

const commonStyles: CSSProperties = {
  borderLeft: "unset",
  borderRight: "unset",
  borderTop: "unset",
  marginBottom: "unset",
};

const ContentGenerationForm: React.FC = () => {
  const [urlActive, setUrlActive] = useState(false);

  const {
    handleContentGenerateByUrl,
    handleContentGenerateByKeywords,
    isUrlLoading,
    isKeywordsLoading,
  } = useContentGeneration();

  const generatedContent = useAppSelector(
    (state) => state.contentCreation.aiContent.contents
  );

  if (!generatedContent.length)
    return (
      <InitialFormContainer>
        <Button.Group>
          <Button positive={urlActive} onClick={() => setUrlActive(true)}>
            URL
          </Button>
          <Button.Or />
          <Button positive={!urlActive} onClick={() => setUrlActive(false)}>
            Keywords
          </Button>
        </Button.Group>
        <Formik
          initialValues={urlActive ? urlFormValues : keywordsFormValues}
          onSubmit={(values) => {
            if (urlActive && "url" in values) {
              handleContentGenerateByUrl(values);
            } else if (!urlActive && "keywords" in values) {
              handleContentGenerateByKeywords(values);
            }
          }}
          validationSchema={
            urlActive ? urlValidationSchema : keywordsValidationSchema
          }
        >
          {({ handleSubmit }) => (
            <Form.Form
              onSubmit={handleSubmit}
              style={{ padding: "unset", margin: "unset" }}
            >
              <InitialFormWrapper>
                {urlActive ? (
                  <Form.Input
                    type="url"
                    name="url"
                    placeholder="Enter a URL"
                    style={{
                      boxShadow: "unset",
                      padding: "0.25rem 0.5rem",
                      minWidth: "64vw",
                      ...commonStyles,
                    }}
                  />
                ) : (
                  <Form.MultiSelectDropdownField
                    name="keywords"
                    placeholder="Enter a keywords"
                    style={{
                      padding: "0.25rem 0.5rem",
                      maxWidth: "64vw",
                      ...commonStyles,
                    }}
                  />
                )}
                <Form.Button
                  variant="alpha"
                  content={
                    isKeywordsLoading || isUrlLoading ? (
                      <PulseLoader size={8} variant="white" />
                    ) : (
                      "Generate"
                    )
                  }
                  size="small"
                  type="submit"
                />
              </InitialFormWrapper>
            </Form.Form>
          )}
        </Formik>
        {isKeywordsLoading || isUrlLoading ? (
          <Flex justify="center">
            <Text content="Your personalized, highly engaging Tweets will be ready in 60 seconds" />
          </Flex>
        ) : null}
      </InitialFormContainer>
    );

  return (
    <FormContainer>
      <Button.Group>
        <Button positive={urlActive} onClick={() => setUrlActive(true)}>
          URL
        </Button>
        <Button.Or />
        <Button positive={!urlActive} onClick={() => setUrlActive(false)}>
          Keywords
        </Button>
      </Button.Group>
      <Formik
        initialValues={urlActive ? urlFormValues : keywordsFormValues}
        onSubmit={(values) => {
          if (urlActive && "url" in values) {
            handleContentGenerateByUrl(values);
          } else if (!urlActive && "keywords" in values) {
            handleContentGenerateByKeywords(values);
          }
        }}
        validationSchema={
          urlActive ? urlValidationSchema : keywordsValidationSchema
        }
      >
        {({ handleSubmit }) => (
          <Form.Form
            onSubmit={handleSubmit}
            style={{ padding: "unset", margin: "unset" }}
          >
            <FormWrapper>
              {urlActive ? (
                <Form.Input
                  type="url"
                  name="url"
                  placeholder="Enter a URL"
                  style={{ boxShadow: "unset" }}
                />
              ) : (
                <Form.MultiSelectDropdownField
                  name="keywords"
                  placeholder="Enter a keywords"
                  style={{
                    borderLeft: "unset",
                    borderRight: "unset",
                    borderTop: "unset",
                    marginBottom: "unset",
                    padding: "0.25rem 0.5rem",
                  }}
                />
              )}
              <Form.Button
                variant="alpha"
                content={
                  isKeywordsLoading || isUrlLoading ? (
                    <PulseLoader size={8} variant="white" />
                  ) : (
                    "Generate"
                  )
                }
                size="small"
                type="submit"
              />
            </FormWrapper>
          </Form.Form>
        )}
      </Formik>
      <DolphyHelp />
    </FormContainer>
  );
};

export default ContentGenerationForm;

import { helpOptions } from "config/ContentCreation";
import { OptionWrapper } from "./HelpOptions.styles";
import OptionCard from "./OptionCard";
import { useOnClickOutside } from "hooks";

type TProps = {
  onClose: () => void;
};

const HelpOptions: React.FC<TProps> = ({ onClose }) => {
  const optionRef = useOnClickOutside<HTMLDivElement>(onClose);

  return (
    <OptionWrapper ref={optionRef}>
      {helpOptions.map(({ id, description, src, icon }) => (
        <OptionCard key={id} description={description} icon={icon} src={src} />
      ))}
    </OptionWrapper>
  );
};

export default HelpOptions;

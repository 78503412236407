import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const FormContainer = styled.div`
  padding-inline: ${helper.getSpacing("medium")};
  margin-top: ${helper.getSpacing("extraSmall")};
  display: grid;
  grid-template-columns: 2fr 12fr 2fr;
  align-items: center;
  gap: ${helper.getSpacing("small")};
  height: 40px;

  .ui.positive.buttons .button,
  .ui.positive.button {
    background-color: ${({ theme }) => theme.colors.alpha};
    color: #ffffff;
    text-shadow: none;
    background-image: none;
  }

  .ui.buttons .or {
    z-index: unset;
  }
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 11fr 1fr;
  align-items: center;
  gap: ${helper.getSpacing("small")};
`;

const InitialFormContainer = styled.div`
  padding-inline: ${helper.getSpacing("medium")};
  margin-top: ${helper.getSpacing("extraLarge")};
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("large")};
  height: 40px;

  .ui.buttons {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ui.positive.buttons .button,
  .ui.positive.button {
    background-color: ${({ theme }) => theme.colors.alpha};
    color: #ffffff;
    text-shadow: none;
    background-image: none;
  }

  .ui.buttons .or {
    z-index: unset;
  }
`;

const InitialFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${helper.getSpacing("large")};
`;

export { FormContainer, FormWrapper, InitialFormContainer, InitialFormWrapper };

import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Content = styled.div`
  width: 80%;
  height: 70%;
  margin: auto;
  overflow: auto;
  padding: ${helper.getSpacing("extraSmall")};
  ${helper.getStyledScrollbar()};
`;

export { Content };

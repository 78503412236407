import ReactPlayer from "react-player";
import styled from "styled-components";

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;

  .player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

interface Props {
  url: string;
}

const Player = ({ url }: Props) => {
  return (
    <PlayerWrapper>
      <ReactPlayer
        className="player"
        width="100%"
        height="100%"
        controls
        url={url}
      />
    </PlayerWrapper>
  );
};

export default Player;

import { useBoolean } from "hooks";
import { HelpButton } from "./DolphyHelp.styles";
import HelpOptions from "./HelpOptions";

const DolphyHelp: React.FC = () => {
  const [open, setOpen, setClose] = useBoolean();

  return (
    <>
      <HelpButton
        icon="info"
        variant="beta"
        size="small"
        color="black"
        content="Help"
        onClick={setOpen}
      />
      {open ? <HelpOptions onClose={setClose} /> : null}
    </>
  );
};

export default DolphyHelp;
